import React from 'react'

class Exp extends React.Component {
    render() {
        return (
            <div className='margin'>
                <div className='teamEx'>
                    <p><strong>{this.props.name}</strong></p>
                    <p>{this.props.time}</p>
                </div>
                <div className='teamEx'>
                    <p>{this.props.loc}</p>
                    <p>{this.props.hours}</p>
                </div>
                <p><i>{this.props.job}</i></p>
                <br />
                <p>{this.props.info}</p>
                <div className='des' />
            </div>
        )
    }
}

export default Exp