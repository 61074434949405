import React from 'react'

class Certifications extends React.Component {
    render() {
        return (
            <div className='description'>
                <div className={'tag t ' + this.props.color}>{this.props.title}</div>
                <div className='des' />
                <img className='certifications' src={this.props.img} alt={this.props.title + ' License'} />
                <p> {this.props.text} </p>
                <br /><br />
                <p> {this.props.date} </p>
            </div>
        )
    }
}

export default Certifications