import React from 'react'

class Skills extends React.Component {
    render() {
        return (
            <div className='description'>
                <div className={'tag t ' + this.props.color}>{this.props.title}</div>
                <p className='type'>{this.props.type}</p>
                <div className='des' />
                <p>
                    {
                        this.props.text.split('\n').map((line, i) => {
                            return (<span key={i}>{line}<br /></span>)
                        })
                    }
                </p>
            </div>
        )
    }
}

export default Skills