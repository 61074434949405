import React from 'react'
//import Popup from '../controls/Popup'
import STLViewer from 'stl-viewer'

class Item3D extends React.Component {
    state = {
        isOpen: false,
        text: null
    }

    handler() {
        this.setState({ isOpen: !this.state.isOpen })
    }

    item = (
        <STLViewer
            className='stl'
            width={200}
            height={200}
            model={this.props.url}
            modelColor='#FFFFFF'
            lights={[5, 5, 5]}
            lightColor='#4ADEDE'
            backgroundColor='#282828'
            rotate={true}
            orbitControls={true}
        />
    )

    render() {
        return (
            <>
                {/*this.state.isOpen ? <Popup handler={() => { this.handler() }} title={this.props.title} content={this.item} /> : ''*/}
                <div className='projectItem projectItemNoC' onClick={() => { this.handler() }}>
                    {this.item}
                    <h1 className='projectTitle'>
                        {this.props.title}
                    </h1>
                    <p className='projectDescription'>
                        {this.props.description}
                    </p>
                </div>
            </>
        );
    }
}
export default Item3D