import React from 'react'
import { motion } from "framer-motion"
import UP from '../../assets/img/Shape/F0F0F0/RH.svg'

class Up extends React.Component {
    render() {
        return (
            <motion.div
                whileHover={{ scale: 1.2 }}
                whileTap={{ scale: 0.8 }}
                className='up color8'
                onClick={() => {
                    window.location.hash = 'intro';
                    window.location.hash = ' ';
                }}
            >
                <img className='upIcon' src={UP} alt='Up to top' />
            </motion.div>
        )
    }
}

export default Up