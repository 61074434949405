// https://github.com/lucagez/sky/blob/master/src/components/sky/sky.js

import React from 'react'
import PropTypes from 'prop-types'
import Item from './item'

class Sky extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            moves: [],
        }
        this.movement = this.movement.bind(this);
    }

    componentDidMount() {
        const many = this.props.num;
        const temp_moves = [];
        for (let i = 0; i < many; i++) {
            temp_moves.push(this.movement());
        }
        this.setState({
            moves: temp_moves
        });
    }

    movement() {
        const rotation = Math.floor((Math.round(Math.random()) * 2 - 1) * 600);
        const fromX = Math.floor((Math.random() * window.innerWidth));
        const fromY = Math.floor((Math.random() * window.innerHeight));
        const temp = {
            rotation,
            fromX,
            fromY
        };
        return temp;
    }

    render() {
        const { images, background, size, time } = this.props;
        const outerStyle = {
            position: 'absolute',
            top: '0',
            left: '0',
            width: '100%',
            height: '100%',
            margin: '0',
            padding: '0',
            overflow: 'hidden',
            zIndex: '-1',
            background
        }

        return (
            <div style={outerStyle} id="sky">
                {this.state.moves.map((e, i) => {
                    const conditional = Math.floor(Math.random() * Object.keys(images).length);
                    const aSize = `${(size + ((Math.random() - 0.5) * 50))}px`;
                    return <Item
                        what={images[conditional]}
                        from={[e.fromX, e.fromY]}
                        rotation={e.rotation}
                        size={aSize}
                        time={time}
                        key={i}
                    />
                })}
            </div>
        );
    }
}


Sky.defaultProps = {
    size: 80,
    background: '',
    time: 20
}

Sky.propTypes = {
    size: PropTypes.number,
    background: PropTypes.string,
    time: PropTypes.number,
    images: PropTypes.array.isRequired
}

export default Sky;