import React from 'react'
import Resume from '../../assets/Resume_JunPark.pdf'

class Projects extends React.Component {
    render() {
        return (
            <>
                <h1>Resume</h1>
                <div className='pageBody'>
                    <embed src={Resume} width='100%' height='1000px' type='application/pdf' />
                </div>
                <p>Try with Chrome if PDF viewer is not working properly, or you can download the PDF file from <a href={Resume}>here</a>.</p>
            </>
        )
    }
}

export default Projects