import React from 'react'
import { connect } from 'react-redux'
import { compose } from 'redux'
import * as actions from '../reducers/actions'

import Intro from '../components/Intro'
import Up from '../components/controls/Up'
import Footer from '../components/Footer'
import GetTouch from '../components/GetTouch'

import AboutMe from './tabs/AboutMe.js'
import Projects from './tabs/Projects.js'
import Design from './tabs/Design.js'
import Experience from './tabs/Experience.js'
import Resume from './tabs/Resume.js'

class Main extends React.Component {
    render() {
        var page
        switch (this.props.tab) {
            case 0:
                page = <AboutMe />
                break
            case 1:
                page = <Experience />
                break
            case 2:
                page = <Projects />
                break
            case 3:
                page = <Design />
                break
            case 4:
                page = <Resume />
                break
            default:
                page = <AboutMe />
        }
        return (
            <>
                <Intro />
                <Up />
                <div className='page' id='top'>
                    {page}
                </div>
                <GetTouch />
                <Footer />
            </>
        )
    }
}

const mapStateToProps = (state) => ({
    tab: state.tab
})
export default compose(connect(mapStateToProps, actions))(Main)