import React from 'react'
import { motion } from 'framer-motion'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"

class Footer extends React.Component {
    render() {
        return (
            <>
                <motion.div
                    className={'sns ' + this.props.color}
                    onClick={() => {
                        window.open(this.props.URL)
                    }}
                    whileHover={{ scale: 1.2, rotate: 360 }}
                    whileTap={{
                        scale: 0.8,
                        rotate: 0,
                        borderRadius: "100%"
                    }}
                    transition='1s'
                >
                    <FontAwesomeIcon icon={this.props.icon} size='2x' />
                </motion.div>
            </>
        )
    }
}

export default Footer
