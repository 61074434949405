import React from 'react'
import Skills from '../../components/controls/Skills'
import Certifications from '../../components/controls/Certifications'
import Exp from '../../components/controls/Exp'
import Data from '../../assets/Experience.json'

import Excel from '../../assets/img/Certifications/Excel.png'
import PPT from '../../assets/img/Certifications/PowerPointPresentation.png'
import Internet from '../../assets/img/Certifications/Internet.png'
import GTQ3 from '../../assets/img/Certifications/GTQ3.png'
import GTQ2 from '../../assets/img/Certifications/GTQ2.png'

class Experience extends React.Component {
  state = {
    title: 'Skills',
    type: '',
    text: 'For more information, click the items above.',
    color: 'color0'
  }

  handler(title, type, text, color) {
    this.setState(
      {
        title: title,
        type: type,
        text: text,
        color: color
      })
  }

  getSkills(json, type, l, color) {
    var set = []
    for (var i = 0; i < json.length; i++) {
      const d = json[i]
      set.push(
        <div
          key={i + l}
          tabIndex={i + l}
          className={'tag ' + color}
          onClick={() =>
            this.handler(d['Name'], type, d['Text'], color)
          }>
          {d['Name']}
        </div>
      )
    }
    return set
  }

  getAct(json, l) {
    var set = []
    for (var i = 0; i < json.length; i++) {
      const d = json[i]
      set.push(
        <Exp
          key={l++}
          name={d['name']}
          loc={d['loc']}
          time={d['time']}
          job={d['job']}
          hours={d['hours']}
          info={d['info']}
        />
      )
    }
    return set
  }

  render() {
    var skills = []
    const PL = Data['Programming language']
    skills = skills.concat(this.getSkills(PL, 'Programming language', skills.length, 'color8'))
    const LF = Data['Library / Framework']
    skills = skills.concat(this.getSkills(LF, 'Library / Framework', skills.length, 'color1'))
    const GM = Data['Game engine']
    skills = skills.concat(this.getSkills(GM, 'Game engine', skills.length, 'color3'))
    const HW = Data['Hardware']
    skills = skills.concat(this.getSkills(HW, 'Hardware', skills.length, 'color12'))
    const SW = Data['Software']
    skills = skills.concat(this.getSkills(SW, 'Software', skills.length, 'color9'))
    const ETC = Data['ETC']
    skills = skills.concat(this.getSkills(ETC, 'Et cetera', skills.length, 'color5'))

    var l = skills.length
    var clubAct = []
    const CA = Data['Club Activities']
    clubAct = this.getAct(CA, l)

    l += clubAct.length
    var teanAct = []
    const TA = Data['Team Activities']
    teanAct = this.getAct(TA, l)

    return (
      <>
        <h1>Experience</h1>
        <div className='pageBody'>
          <h2>Skills</h2>
          <div className='margin'>
            {skills}
            <Skills title={this.state.title} type={this.state.type} color={this.state.color} text={this.state.text} />
          </div>
        </div>
        <div className='pageBody'>
          <h2>Summer program</h2>
          <Exp
            name={'Big Data: Using Data Science to Address Social Challenges'}
            loc={'Cornell University, Ithaca, NY'}
            time={'July. 2020'}
            job={'Summer Program'}
            hours={''}
            info={'Learned about important policy issues through economics and sociological lenses, discover how big data is being used to address policy problems, and develop technical skills (using R language) needed to use data to address policy problems.'}
          />
        </div>
        <div className='pageBody'>
          <h2>Club Activities</h2>
          {clubAct}
        </div>
        <div className='pageBody'>
          <h2>Team Activities</h2>
          {teanAct}
        </div>
        <div className='pageBody'>
          <h2>Competitions and Awards</h2>
          <div className='margin'>
            <p>
              ● Maur Hill-Mount Academy Service Hour Awards (total 41 hours) <br /> <br />
              ● United States of America Computing Olympiad, Pass Silver Level <br /> <br />
              ● 2019 First Tech Challenge, Won Qualification League <br /> <br />
              ● 2019 - 2020 Avon Old Farms Dean's lists (academic award) <br /> <br />
              ● 2020 - 2021 Avon Old Farms Head of School List (academic award) <br /> <br />
              ● 2021 The Founder's Medal - Academic Excellence <br /> <br />
              ● For Excellence In Advanced Computer Science - Mobile Development & Application Development
            </p>
          </div>
        </div>
        <div className='pageBody'>
          <h2>Advanced Placement</h2>
          <div className='margin'>
            <p>
              ● 2020 Computer Sience A, Score: 5  <br /> <br />
              ● 2021 Physics C: Mechanics, Score: NA
            </p>
          </div>
        </div>
        <div className='pageBody'>
          <h2>Certifications and Licenses</h2>
          <div className='margin'>
            <Certifications
              title='ITQ Power Point Presentation'
              color='color0'
              img={PPT}
              text='I received the Power Point Presentation Certification from Information Technology Qualification (ITQ).'
              date='Certified date: 2013.09.12'
            />
            <Certifications
              title='ITQ Internet'
              color='color0'
              img={Internet}
              text='I received the Internet Information Utilization License from Information Technology Qualification (ITQ).'
              date='Certified date: 2014.10.02'
            />
            <Certifications
              title='ITQ Excel'
              color='color0'
              img={Excel}
              text='I received the Excel License from Information Technology Qualification (ITQ).'
              date='Certified date: 2015.03.05'
            />
            <Certifications
              title='GTQ 3'
              color='color0'
              img={GTQ3}
              text='I received Graphic Technology Qualification Level 3 (Photoshop Level 3 License) from Korean Certified Graphic License Test.'
              date='Certified date: 2012.12.24'
            />
            <Certifications
              title='GTQ 2'
              color='color0'
              img={GTQ2}
              text='I received Graphic Technology Qualification Level 2 (Photoshop Level 2 License) from Korean Certified Graphic License Test. (Level 2 is higher level than Level 3.)'
              date='Certified date: 2014.03.19'
            />
          </div>
        </div>
      </>
    )
  }
}

export default Experience