import React from 'react'
import ReactDOM from 'react-dom'
import './assets/css/index.css'

import { createStore, applyMiddleware } from 'redux'
import { Provider } from 'react-redux'
import reduxThunk from 'redux-thunk'
import store from './reducers/store'

import Main from './pages/Main'
import ASCII from './assets/ASCII.txt'

const css = 'color: #D3DE16';
fetch(ASCII).then(r => r.text())
    .then(text => {
        console.log('%cDesigned by', css)
        console.log('%c' + text, css)
        console.log('%cHi there! I am glad that you are interested in my portfolio!', css)
        console.log('%cHope you enjoy your stay!', css)
    });

ReactDOM.render(
    <Provider store={createStore(store, applyMiddleware(reduxThunk))}>
        <Main />
    </Provider>,
    document.getElementById('root')
)
