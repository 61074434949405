import React from 'react'
import { motion } from 'framer-motion'
import Tab from '../components/Tab'
import Sky from './Sky/Sky'
import Portrait from '../assets/img/Intro.svg'

class Intro extends React.Component {

    importAll = (r) => {
        return r.keys().map(r);
    }

    render() {
        const images1 = this.importAll(require.context('../assets/img/Shape/12CBC4', false, /\.(png|jpe?g|svg)$/))
        const images2 = this.importAll(require.context('../assets/img/Shape/C4E538', false, /\.(png|jpe?g|svg)$/))
        const images3 = this.importAll(require.context('../assets/img/Shape/F0F0F0', false, /\.(png|jpe?g|svg)$/))
        const images4 = this.importAll(require.context('../assets/img/Shape/FFC312', false, /\.(png|jpe?g|svg)$/))
        const images5 = this.importAll(require.context('../assets/img/Shape/ED4C67', false, /\.(png|jpe?g|svg)$/))
        const images6 = this.importAll(require.context('../assets/img/Shape/D980FA', false, /\.(png|jpe?g|svg)$/))
        const images = images1.concat(images2, images3, images4, images5, images6)

        return (
            <>
                <div className='bg' id='intro'>
                    <div className='intro'>
                        <Sky
                            images={images}
                            num={18}
                            time={10}
                            size={80}
                        />
                        <motion.div
                            className='center'
                            animate={{
                                opacity: [0, 1],
                                rotate: [0, -15, 15, 0],
                                scale: [0, 1]
                            }}
                            transition={{ duration: 1 }}
                        >
                            <div className='introBox center'>
                                <img width='100%' height='auto' src={Portrait} alt='Intro' />
                                <Tab />
                            </div>
                        </motion.div>
                    </div>
                </div>
            </>
        )
    }
}
export default Intro