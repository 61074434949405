import React from 'react'
import RTE from 'react-typing-effect'

import Data from '../../assets/Experience.json'

class AboutMe extends React.Component {
  getEducation(json) {
    var set = []
    for (var i = 0; i < json.length; i++) {
      const d = json[i]
      set.push(
        <div key={i} className='margin'>
          <div className='teamEx'>
            <p><strong>{d['name']}</strong></p>
            <p>{d['time']}</p>
          </div>
          <div className='teamEx'>
            <p>{d['loc']}</p>
            <p>{d['grade']}</p>
          </div>
          <p><i>{d['gpa']}</i></p>
          <div className='des' />
        </div>
      )
    }
    return set
  }

  render() {
    return (
      <>
        <h1 className='textCenter'>
          Hello,
          <br />
          {'I am a '}
          <strong>
            <RTE
              speed='80'
              eraseDelay='1200'
              typingDelay='80'
              text={[
                'HyunJun Park.',
                'student.',
                'software engineer.',
                'designer.',
              ]}
            />
          </strong>
        </h1>
        <div className='pageBody'>
          <p>
            Science has forever captivated me. From the simple mobile application to Tesla's autopilot system, code is what makes all this possible. Behind every technological wonder, computer science is what really gives birth to our ideas. Every single line of code functions like human organs, making a project vivacious and active; this is what fuels my interest and passion for coding.
            <br />
            <br />
            Since I first started coding, I have organically picked up different skills and languages and that made my creations possible. At the age of 11, I started coding with a simple game script, which added another layer of joy to the game; today, I serve a full-stack platform, AvonFood, that shares our school's meal information. Every year, I continued to make and learn different things.
            <br />
            <br />
            I, as a Software Engineer, believe that it is my mission to listen to people's inconveniences and strive to improve everyone’s well-being. Moreover, in the spirit of the open source movement, I publish all of my codes to make them accessible to everyone. My life-long goal is to provide a service that leads to a thriving and ever-improving standard of living for all members of society.
            <br />
          </p>
        </div>
        <div className='bgBox aboutBox'>
          <div className='bgCover'>
            <video
              className='bgVid center'
              preload='auto'
              autoPlay={true}
              loop='loop'
              muted='muted'
              volume='0'
              webkit-playsinline=""
            >
              <source src='https://i.imgur.com/WnqQ9SU.mp4' type='video/mp4' />
            </video>
          </div>
        </div>
        <div className='pageBody'>
          <h2>Education</h2>
          {this.getEducation(Data['Education'])}
        </div>
      </>
    )
  }
}

export default AboutMe
