import React from 'react'
import Item3D from '../../components/controls/Item3D'
import Data from '../../assets/Models.json'

class Design extends React.Component {

  get3D() {
    var set = []
    const json = Data['3D']
    for (var i = 0; i < json.length; i++) {
      const d = json[i]
      set.push(
        <Item3D
          key={i}
          title={d['title']}
          description={d['description']}
          url={d['url']}
        />
      )
    }
    return set
  }

  getArtworks(arts) {
    var set = []
    const img = arts
    for (var i = 0; i < img.length; i++) {
      set.push(
        <div className='projectItem' key={'Art' + i}>
          <img className='artThumbnail' src={img[i]} alt={'Artworks' + i} />
        </div>
      )
    }
    return set
  }

  importAll = (r) => {
    return r.keys().map(r);
  }

  render() {
    const arts = this.importAll(require.context('../../assets/img/Designs/', false, /\.(png|jpe?g|svg)$/))
    return (
      <>
        <h1>Design</h1>
        <div className='pageBody'>
          <h2>3D Models</h2>
          {/* <p>3D files that I modeled by using a CAD program, Autodesk Inventor.</p>
          <div className='projectGrid'>
            {this.get3D()}
          </div> */}
          <p>You can check more than hundred of 3D modeled parts from <a href='https://github.com/Nitro1231/3D-Models'>here</a>.</p>
        </div>
        <div className='pageBody'>
          <h2>Youtube Videos</h2>
          <br />
          <div className='ytbox'>
            <iframe className='ytcontent' title='Jon Prezant - Remember Me (lyrics)' width="100%" height="100%" src="https://www.youtube.com/embed/VxXNyxyHNTI" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
          </div>
          <br />
          <div className='ytbox'>
            <iframe className='ytcontent' title='Non Sugar-Free Rocket Project: Firing Tests' width="100%" height="100%" src="https://www.youtube.com/embed/hHK2zC_gKaM" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
          </div>
          <br />
          <div className='ytbox'>
            <iframe className='ytcontent' title='Phone Stand Project - Timelapse' width="100%" height="100%" src="https://www.youtube.com/embed/gIZdQ3jyjPI" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
          </div>
          <br />
          <div className='ytbox'>
            <iframe className='ytcontent' title='New York Time Lapse' width="100%" height="100%" src="https://www.youtube.com/embed/VGOGs1udVqY" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
          </div>
          <br />
          <div className='ytbox'>
            <iframe className='ytcontent' title='Smart Switch Project (3D Printing timelapse and demonstration)' width="100%" height="100%" src="https://www.youtube.com/embed/drDIPHCTaeI" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
          </div>
          <br />
        </div>
        <div className='pageBody'>
          <h2>Artworks</h2>
          <div className='projectGrid'>
            {this.getArtworks(arts)}
          </div>
        </div>
      </>
    )
  }
}

export default Design
