import React from 'react'
//import Popup from '../controls/Popup'

//import MD from 'react-markdown'
import axios from 'axios'

class ProjectItem extends React.Component {
    state = {
        isOpen: false,
        text: null
    }

    handler() {
        this.setState({ isOpen: !this.state.isOpen })
    }

    async readText() {
        if (this.state.text == null) {
            const rsp = await axios.get(this.props.content)
            this.setState({ text: rsp.data })
        }
    }

    render() {
        this.readText()
        return (
            <>
                {/*this.state.isOpen ? <Popup handler={() => { this.handler() }} title={this.props.title} content={<MD source={this.state.text} />} /> : ''*/}
                <div className='projectItem' onClick={() => { this.handler() }}>
                    <img className='thumbnail' src={this.props.image} alt={this.props.title} />
                    <h1 className='projectTitle'>
                        {this.props.title}
                    </h1>
                    <p className='projectDescription'>
                        {this.props.description}
                    </p>
                </div>
            </>
        );
    }
}
export default ProjectItem