import React from 'react'
import LOGO from '../assets/img/LOGO.png'

import SNS from './controls/SNS'
import { faEnvelope, faBook } from '@fortawesome/free-solid-svg-icons'
import { faYoutube, faGithub, faTwitter, faDiscord } from '@fortawesome/free-brands-svg-icons'

class Footer extends React.Component {
    render() {
        return (
            <>
                <div className='footer' >
                    <img className='logo' src={LOGO} alt='Logo' />
                    <div className='snsBox'>
                        <SNS color='color0' icon={faEnvelope} URL={`mailto:junpark0207@gmail.com?cc=hyunjun@nitrostudio.dev;parkh@avonoldfarms.com&subject=[Portfolio]`} />
                        <SNS color='color3' icon={faBook} URL='https://blog.nitrostudio.dev/' />
                        <SNS color='color1' icon={faGithub} URL='https://github.nitrostudio.dev/' />
                        <SNS color='color8' icon={faYoutube} URL='https://youtube.nitrostudio.dev/' />
                        <SNS color='color5' icon={faTwitter} URL='https://twitter.nitrostudio.dev/' />
                        <SNS color='color6' icon={faDiscord} URL='https://discord.nitrostudio.dev/' />
                    </div>
                    <div className='copyright'>
                        © 2020 <strong>HyunJun Park</strong>. All rights reserved.
                    </div>
                </div>
            </>
        )
    }
}

export default Footer
