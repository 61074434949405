import React from 'react'

class GetTouch extends React.Component {
    state = {
        subject: '',
        message: ''
    }

    render() {
        return (
            <>
                <div className='page' >
                    <h1><strong>GET IN TOUCH</strong></h1>
                    <div className='margin'>
                        <h2>Email</h2> <br />
                        <div className='tag t color8'>junpark0207@gmail.com</div>
                        <div className='tag t color8'>hyunjup4@uci.edu</div>
                        <div className='tag t color8'>hyunjun@nitrostudio.dev</div>
                    </div>
                    <div className='emailBox'>
                        <input
                            className='emailInput'
                            type='text'
                            placeholder='Subject'
                            data-gramm_editor={false} // to prevent page height extension
                            onChange={(e) => {
                                this.setState({ subject: e.target.value })
                            }}
                        />
                        <textarea
                            className='emailText'
                            placeholder='Message'
                            data-gramm_editor={false} // to prevent page height extension
                            onChange={(e) => {
                                this.setState({ message: e.target.value })
                            }}
                        />
                        <div
                            className='emailBtn color0'
                            onClick={() => {
                                window.open(`mailto:junpark0207@gmail.com?cc=hyunjun@nitrostudio.dev;parkh@avonoldfarms.com&subject=[Portfolio]${this.state.subject}&body=${this.state.message}`)
                            }}
                        >
                            Send
                        </div>
                    </div>
                </div>
            </>
        )
    }
}

export default GetTouch
